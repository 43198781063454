/**
 * @ngdoc service
 * @name BpmnGatewayNodeService
 * @module flowingly.bpmn.modeler
 *
 * @description A service for creating BPMN Gateway nodes.
 *
 * ## Notes
 *
 * ### Model Usage
 *  { key: 201, category: "exclusiveGateway", text: "Decision" }
 *
 *  There are two types of nodes (node/palette node). The first is what gets drawn on the canvas;
 *  the second what gets drawni the palette and dragged on to the canvas.
 * ###API
 * * getNode -  Get an gateway node defined by the (optional) options
 * * getPaletteNode - Get a gateway palette node defined by the (optional) options
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/b093fe974b2b3eb0982893197d03250818171b7f/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.gateway.service.js?at=master
 */

'use strict';
import { Services } from '@Shared.Angular/@types/services';
import angular from 'angular';

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnGatewayNodeService', BpmnGatewayNodeService);

BpmnGatewayNodeService.$inject = [
  'goService',
  'BpmnPartsFactory',
  'BPMN_CONSTANTS',
  'BpmnCommonService',
  'modelerValidationErrorsService',
  'flowinglyConstants'
];

function BpmnGatewayNodeService(
  goService,
  BpmnPartsFactory,
  BPMN_CONSTANTS,
  BpmnCommonService,
  modelerValidationErrorsService,
  flowinglyConstants: Services.FlowinglyConstants
) {
  //--------------------------------------------DEFAULTS------------------------------------------
  const COMMON_GATEWAY_OPTIONS = {
    name: 'Icon',
    width: BPMN_CONSTANTS.diagram.gatewayIcon.outerWidth,
    height: BPMN_CONSTANTS.diagram.gatewayIcon.outerHeight,
    portId: '',
    fromLinkable: true,
    toLinkable: true,
    cursor: 'pointer',
    fromSpot: goService.Spot.NotLeftSide,
    toSpot: goService.Spot.MiddleLeft
  };

  const GATEWAY_DAFAULT_COLOR = '#A3A3A3';
  const GATEWAY_PALETTE_FILL_COLOR = '#efefef';
  const GATEWAY_CANVAS_SIZE = 70;
  const $GO = goService.GraphObject.make;
  const defaults = {
    GatewayNodeEditable: BPMN_CONSTANTS.MakeNodesEditable,
    GatewayNodeSize: 80,
    GatewayNodeSymbolSize: 25,
    GatewayNodeFill: BPMN_CONSTANTS.GatewayNodeFill,
    GatewayNodeStroke: GATEWAY_DAFAULT_COLOR,
    GatewayNodeStrokeWidth: BPMN_CONSTANTS.GatewayNodeStrokeWidth,
    GatewayNodePaletteStrokeWidth: BPMN_CONSTANTS.GatewayNodePaletteStrokeWidth,
    GatewayNodeSymbolStroke: BPMN_CONSTANTS.GatewayNodeStroke,
    GatewayNodeSymbolFill: BPMN_CONSTANTS.GatewayNodeFill,
    GatewayNodePaletteFill: GATEWAY_PALETTE_FILL_COLOR,
    GatewayNodeSymbolStrokeWidth: 2.5
  };

  const CURSOR_MOVE_OPTIONS = {
    fill: 'transparent',
    stroke: null,
    cursor: 'move',
    width: BPMN_CONSTANTS.diagram.gatewayIcon.outerWidth - 10,
    height: BPMN_CONSTANTS.diagram.gatewayIcon.outerHeight - 10
  };
  const service = {
    getNode: getNode,
    getPaletteNode: getPaletteNode
  };
  return service;

  /// PUBLIC ///////////////////////////////////////////////////////////////////////

  function getNode(category, options) {
    angular.extend(defaults, options); //override defaults, if options supplied

    switch (category) {
      case flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY:
        return getExclusiveGatewayNode();

      case flowinglyConstants.nodeCategory.DIVERGE_GATEWAY:
        return getDivergeGatewayNode();

      case flowinglyConstants.nodeCategory.CONVERGE_GATEWAY:
        return getMergeGatewayNode();

      default:
        return getGenericGateway();
    }
  }

  // @TODO flowingly.bpmn.activity.service has the
  // exact same function, we should merge this into
  // a service.
  function getBorderColor(goNodeModel) {
    const hasError = modelerValidationErrorsService.hasErrors(goNodeModel.key);
    return hasError
      ? BPMN_CONSTANTS.Theme.Error.Border.Color
      : BPMN_CONSTANTS.ActivityNodeBorder;
  }

  // @TODO flowingly.bpmn.activity.service has the
  // exact same function, we should merge this into
  // a service.
  function getBorderWidth(goNodeModel) {
    const hasError = modelerValidationErrorsService.hasErrors(goNodeModel.key);
    return hasError
      ? BPMN_CONSTANTS.Theme.Error.Border.StrokeWidth / 2 // divide by 2 so it will match with the activity's width
      : 0;
  }

  function getShapeForBorder() {
    return $GO(
      goService.Shape,
      'Square',
      {
        fill: 'white',
        stroke: '#FF0000',
        strokeWidth: 4,
        name: 'outline'
      },
      // Business Rule for FLOW-4654, when valdiation the borders of
      // the nodes should turn red if they contain an error.
      new goService.Binding('stroke', '', getBorderColor),
      new goService.Binding('strokeWidth', '', getBorderWidth)
    );
  }

  function getDecisionGraphIcon(opts = {}) {
    return $GO(
      goService.Panel,
      'Auto',
      // this shape is solely for giving the decision gateway a
      // box border for when an error has occured.
      getShapeForBorder(),
      $GO(
        goService.Panel,
        'Auto',
        $GO(goService.Picture, {
          ...COMMON_GATEWAY_OPTIONS,
          ...opts,
          source: ASSETS_PATH + '/decision.svg'
        }),
        $GO(goService.Shape, 'Diamond', {
          ...CURSOR_MOVE_OPTIONS
        })
      ),
      ...(BpmnCommonService.shouldShowConnectorPoints()
        ? [
            BpmnCommonService.makePort(
              'ConnectorPort',
              goService.Spot.Right,
              new goService.Spot(1, 0.5, 0.5, 0)
            )
          ]
        : [])
    );
  }

  function getDivergeGraphIcon(opts = {}) {
    return $GO(
      goService.Panel,
      'Auto',
      // this shape is solely for giving the decision gateway a
      // box border for when an error has occured.
      getShapeForBorder(),
      $GO(
        goService.Panel,
        'Auto',
        $GO(goService.Picture, {
          ...COMMON_GATEWAY_OPTIONS,
          ...opts,
          source: ASSETS_PATH + '/diverge.svg'
        }),
        $GO(goService.Shape, 'Diamond', {
          ...CURSOR_MOVE_OPTIONS
        })
      ),
      ...(BpmnCommonService.shouldShowConnectorPoints()
        ? [
            BpmnCommonService.makePort(
              'ConnectorPort',
              goService.Spot.Right,
              new goService.Spot(1, 0.5, 0.5, 0)
            )
          ]
        : [])
    );
  }

  function getMergeGraphIcon(opts = {}) {
    return $GO(
      goService.Panel,
      'Auto',
      // this shape is solely for giving the decision gateway a
      // box border for when an error has occured.
      getShapeForBorder(),
      $GO(
        goService.Panel,
        'Auto',
        $GO(goService.Picture, {
          ...COMMON_GATEWAY_OPTIONS,
          ...opts,
          source: ASSETS_PATH + '/merge.svg'
        }),
        $GO(goService.Shape, 'Diamond', {
          ...CURSOR_MOVE_OPTIONS
        })
      ),
      ...(BpmnCommonService.shouldShowConnectorPoints()
        ? [
            BpmnCommonService.makePort(
              'ConnectorPort',
              goService.Spot.Right,
              new goService.Spot(1, 0.5, 0.5, 0)
            )
          ]
        : [])
    );
  }

  ///
  /// Get a Gateway node defined by the (optional) options
  /// If options is not supplied, default values will be used
  ///
  function getPaletteNode(options, isExclusiveGateway, isConverge) {
    angular.extend(defaults, options); //override defaults, if options supplied
    return getGatewayPaletteNode(isExclusiveGateway, isConverge);
  }

  /// PRIVATE ///////////////////////////////////////////////////////////////////////

  //---------------------------------------------HELPERS------------------------------------------

  //---------------------------------------------TEMPLATE------------------------------------------

  /**
   * @deprecated
   */
  function getGenericGateway() {
    const gatewayNodeTemplate = $GO(
      goService.Node,
      'Vertical',
      {
        locationObjectName: 'Icon',
        locationSpot: goService.Spot.Center
        //toolTip: tooltiptemplate
      },
      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify),
      // move a selected part into the Foreground layer, so it isn't obscured by any non-selected parts
      new goService.Binding('layerName', 'isSelected', function (s) {
        return s ? 'Foreground' : '';
      }).ofObject(),
      // can be resided according to the user's desires
      { resizable: false, resizeObjectName: 'SHAPE' },
      $GO(
        goService.Panel,
        'Spot',
        $GO(
          goService.Shape,
          'Diamond',
          {
            strokeWidth: defaults.GatewayNodeStrokeWidth,
            fill: defaults.GatewayNodeFill,
            stroke: defaults.GatewayNodeStroke,
            name: 'SHAPE',
            desiredSize: new goService.Size(
              defaults.GatewayNodeSize,
              defaults.GatewayNodeSize
            ),
            portId: '',
            fromLinkable: true,
            toLinkable: true,
            cursor: 'pointer',
            fromSpot: goService.Spot.NotLeftSide,
            toSpot: goService.Spot.MiddleLeft
          },
          new goService.Binding(
            'desiredSize',
            'size',
            goService.Size.parse
          ).makeTwoWay(goService.Size.stringify)
        ) // end main shape
      ),
      BpmnPartsFactory.getTextBlock('text')
    ); // end goService.Node Vertical

    return gatewayNodeTemplate;
  }

  //TODO RF refactor common parts into one method
  function getExclusiveGatewayNode() {
    const gatewayNodeTemplate = $GO(
      goService.Node,
      'Vertical',
      {
        locationObjectName: 'Icon',
        locationSpot: goService.Spot.Center,
        mouseEnter: function (e, node) {
          ['ConnectorPort'].forEach((portName) =>
            BpmnCommonService.showPort(node, portName)
          );
        },
        mouseLeave: function (e, node) {
          ['ConnectorPort'].forEach((portName) =>
            BpmnCommonService.hidePort(node, portName)
          );
        }
        //toolTip: tooltiptemplate
      },
      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify),
      // move a selected part into the Foreground layer, so it isn't obscured by any non-selected parts
      new goService.Binding('layerName', 'isSelected', function (s) {
        return s ? 'Foreground' : '';
      }).ofObject(),
      // can be resided according to the user's desires
      { resizable: false, resizeObjectName: 'SHAPE' },
      getDecisionGraphIcon(),
      BpmnPartsFactory.getTextBlock(
        'text',
        true,
        undefined,
        'move',
        undefined,
        true
      ),
      { click: BpmnCommonService.nodeClickHandler }
    ); // end goService.Node Vertical

    return gatewayNodeTemplate;
  }

  function getGenericGatewayNode(gatewayGraphIcon, canEditText) {
    return $GO(
      goService.Node,
      'Vertical',
      {
        locationObjectName: 'Icon',
        locationSpot: goService.Spot.Center,
        click: BpmnCommonService.nodeClickHandler,
        mouseEnter: function (e, node) {
          ['ConnectorPort'].forEach((portName) =>
            BpmnCommonService.showPort(node, portName)
          );
        },
        mouseLeave: function (e, node) {
          ['ConnectorPort'].forEach((portName) =>
            BpmnCommonService.hidePort(node, portName)
          );
        }
      },

      gatewayGraphIcon,

      BpmnPartsFactory.getTextBlock(
        'text',
        true,
        undefined,
        'move',
        undefined,
        canEditText
      ),

      new goService.Binding(
        'location',
        'loc',
        goService.Point.parse
      ).makeTwoWay(goService.Point.stringify),
      new goService.Binding('layerName', 'isSelected', (s) =>
        s ? 'Foreground' : ''
      ).ofObject() // move a selected part into the Foreground layer
      // , so it isn't obscured by any non-selected parts
    ); // end goService.Node Vertical
  }

  function getDivergeGatewayNode() {
    return getGenericGatewayNode(getDivergeGraphIcon(), true);
  }

  function getMergeGatewayNode() {
    return getGenericGatewayNode(getMergeGraphIcon(), false);
  }

  function getGatewayPaletteNode(isExclusiveGateway, isConverge) {
    let imageUrl;
    if (isExclusiveGateway) {
      imageUrl = ASSETS_PATH + '/decision.svg';
    } else {
      if (isConverge) {
        imageUrl = ASSETS_PATH + '/merge.svg';
      } else {
        imageUrl = ASSETS_PATH + '/diverge.svg';
      }
    }

    return BpmnPartsFactory.paletteIcon(
      $GO(goService.Picture, {
        source: imageUrl,
        width: !BpmnCommonService.isInternetExplorer
          ? BPMN_CONSTANTS.palette.icon.innerWidth
          : BPMN_CONSTANTS.palette.iconIE.gateway.innerWidth,
        height: !BpmnCommonService.isInternetExplorer
          ? BPMN_CONSTANTS.palette.icon.innerHeight
          : BPMN_CONSTANTS.palette.iconIE.gateway.innerHeight,
        scale: !BpmnCommonService.isInternetExplorer
          ? 1
          : BPMN_CONSTANTS.palette.iconIE.gateway.scale
      })
    );
  }
}

export type BpmnGatewayNodeServiceType = ReturnType<
  typeof BpmnGatewayNodeService
>;
